import styled from '@emotion/styled'
import { Box, IconButton } from '@mui/material'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

import { CopyIcon } from '@/components/icon/CopyIcon'
import { functionalColor } from '@/utils/themeConfigs/customTheme'
import { useTextCopy } from '@/utils/hooks/useTextCopy'

const InlineCode = styled.code`
  background-color: ${functionalColor.lightBeige};
  border-radius: 6px;
  margin: 4px;
  white-space: pre-wrap;
`

const CopyButtonContainer = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

const Container = styled(Box)`
  position: relative;
`

type CodeHighLightProps = {
  // className: Markdownのコードブロックで指定された言語が入る 例）language-javascript
  className: string | undefined
  children: React.ReactNode
}

export const CodeHighLight = ({ className, children }: CodeHighLightProps) => {
  const { copyToClipboard } = useTextCopy()

  const match = /language-(\w+)/.exec(className || '')
  const language = match && match[1] ? match[1] : undefined
  // childrenがundefinedになることあるので対策
  const code = String(children ?? '').replace(/\n$/, '')

  const handleCopyToClipboard = () => {
    return void copyToClipboard(code)
  }

  // 取得した言語スタイルでコードブロック表示
  if (language) {
    return (
      <Container>
        <CopyButtonContainer onClick={handleCopyToClipboard}>
          <CopyIcon />
        </CopyButtonContainer>
        <SyntaxHighlighter language={language}>{code}</SyntaxHighlighter>
      </Container>
    )
  }

  // インラインコード
  return <InlineCode>{code}</InlineCode>
}
