import { useSnackbarContext } from '@/context/SnackbarContext'

export const useTextCopy = () => {
  const { showSnackbar } = useSnackbarContext()

  const copyToClipboard = async (text: string) => {
    await global.navigator.clipboard.writeText(text)
    if (showSnackbar) {
      showSnackbar({
        text: 'コピーしました。',
        severity: 'success',
      })
    }
  }

  return {
    copyToClipboard,
  }
}
