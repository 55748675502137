export const CopyIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.33325 5.33333V3.46666C5.33325 2.71992 5.33325 2.34656 5.47858 2.06134C5.60641 1.81046 5.81038 1.60648 6.06126 1.47865C6.34648 1.33333 6.71985 1.33333 7.46659 1.33333H12.5333C13.28 1.33333 13.6534 1.33333 13.9386 1.47865C14.1895 1.60648 14.3934 1.81046 14.5213 2.06134C14.6666 2.34656 14.6666 2.71992 14.6666 3.46666V8.53333C14.6666 9.28007 14.6666 9.65343 14.5213 9.93865C14.3934 10.1895 14.1895 10.3935 13.9386 10.5213C13.6534 10.6667 13.28 10.6667 12.5333 10.6667H10.6666M3.46659 14.6667H8.53325C9.27999 14.6667 9.65336 14.6667 9.93857 14.5213C10.1895 14.3935 10.3934 14.1895 10.5213 13.9386C10.6666 13.6534 10.6666 13.2801 10.6666 12.5333V7.46666C10.6666 6.71992 10.6666 6.34656 10.5213 6.06134C10.3934 5.81046 10.1895 5.60648 9.93857 5.47865C9.65336 5.33333 9.27999 5.33333 8.53325 5.33333H3.46659C2.71985 5.33333 2.34648 5.33333 2.06126 5.47865C1.81038 5.60648 1.60641 5.81046 1.47858 6.06134C1.33325 6.34656 1.33325 6.71993 1.33325 7.46666V12.5333C1.33325 13.2801 1.33325 13.6534 1.47858 13.9386C1.60641 14.1895 1.81038 14.3935 2.06126 14.5213C2.34648 14.6667 2.71985 14.6667 3.46659 14.6667Z'
        stroke='#A1A1A1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
