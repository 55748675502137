import { useCallback, useEffect, useRef, useState } from 'react'
import { ChatHistoryType } from '@/components/layout/Chat/ChatHistory'
import { usePathname } from 'next/navigation'

type UseAutoScrollType = {
  chatHistory: ChatHistoryType
}

export const useAutoScroll = ({ chatHistory }: UseAutoScrollType) => {
  const [arrowAutoScroll, setArrowAutoScroll] = useState<boolean>(true)
  const [currentPosition, setCurrentPosition] = useState<number>(0)
  const pathname = usePathname()

  const scrollRootRef = useRef<HTMLDivElement>(null)

  // スクロールが必要か判定する関数
  const handleSetArrowAutoScroll = useCallback(() => {
    const scrollRoot = scrollRootRef.current
    if (!scrollRoot) return
    // 下方向スクロール&&最下部までスクロールした場合
    if (currentPosition <= scrollRoot?.scrollTop) {
      setArrowAutoScroll(true)
    }
    // 上方向にスクロールした場合、自動スクロールを停止する
    if (currentPosition > scrollRoot?.scrollTop) {
      setArrowAutoScroll(false)
    }
  }, [currentPosition])

  // chatRoomIdが切り替わったら自動スクロールを開始する
  useEffect(() => {
    setArrowAutoScroll(true)
  }, [pathname])

  // チャット履歴が更新された時にスクロールが必要だったらスクロールする
  useEffect(() => {
    const scrollRoot = scrollRootRef.current
    if (scrollRoot && arrowAutoScroll) {
      scrollRoot.scrollTop = scrollRoot?.scrollHeight
      setCurrentPosition(scrollRoot.scrollTop)
    }
  }, [chatHistory, arrowAutoScroll])

  return {
    scrollRootRef,
    handleSetArrowAutoScroll,
  }
}
