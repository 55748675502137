import NextImage from 'next/image'
import styled from '@emotion/styled'
import { Box, BoxProps, Button, Typography } from '@mui/material'

import { SaveIcon } from '@/components/icon/SaveIcon'
import { textColor } from '@/utils/themeConfigs/customTheme'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useImageDownload } from '@/utils/hooks/useImageDownload'

import { Role } from './ChatBubble'

const Container = styled(Box, {
  shouldForwardProp: (props) => props !== 'role',
})<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ role }) => (role === 'user' ? 'end' : 'start')};
  gap: 10px;
`

const StyledImage = styled(NextImage)`
  height: 280px;
  width: 280px;
  border-radius: 14px;
  opacity: 0.92;
  ${mediaQuery('tab')} {
    height: 200px;
    width: 200px;
  }
`

const SaveButtonText = styled(Typography)`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 1.448;
  color: ${textColor.mediumGray};
`

const Canvas = styled.canvas`
  display: none;
`

type ContainerProps = BoxProps & {
  role: Role
}

type Props = {
  src: string
  title: string
  name: string
  isHistory: boolean
}

export const ChatImageBubble = ({ src, title, name, isHistory }: Props) => {
  const { imageRef, canvasRef, downloadImage } = useImageDownload()

  const handleDownloadImage = () => {
    downloadImage({ fileName: name })
  }

  return (
    <Container role='assistant'>
      <StyledImage
        src={src}
        alt='生成された画像'
        width={280}
        height={280}
        crossOrigin='anonymous'
        title={title}
        ref={imageRef}
      />
      {!isHistory && (
        <Button startIcon={<SaveIcon />} onClick={handleDownloadImage}>
          <SaveButtonText>保存</SaveButtonText>
        </Button>
      )}
      <Canvas ref={canvasRef}></Canvas>
    </Container>
  )
}
