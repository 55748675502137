import styled from '@emotion/styled'
import { Box, BoxProps, Button, Typography } from '@mui/material'

import { CopyIcon } from '@/components/icon/CopyIcon'
import { accentColor, bgColor, textColor } from '@/utils/themeConfigs/customTheme'
import { useTextCopy } from '@/utils/hooks/useTextCopy'

import { Markdown } from './Markdown'

const Container = styled(Box, {
  shouldForwardProp: (props) => props !== 'role',
})<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ role }) => (role === 'user' ? 'end' : 'start')};
`

const Bubble = styled(Box, {
  shouldForwardProp: (props) => props !== 'role' && props !== 'isHistory',
})<BubbleProps>`
  max-width: 532px;
  width: 100%;
  border-radius: ${({ role }) => (role === 'user' ? '14px 14px 0px 14px;' : '14px 14px 14px 0px;')}
  padding: 8px 14px 8px 14px;
  background-color: ${({ role, isHistory }) =>
    role === 'user'
      ? isHistory
        ? accentColor.lightGray
        : accentColor.lightRed
      : bgColor.lightestGray};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.448;
  color: ${textColor.dark};
`

const CopyButtonText = styled(Typography)`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 2.8;
  color: ${textColor.mediumGray};
`

const DatetimeText = styled(Typography)`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 2.8;
  color: ${textColor.mediumGray};
`

const UserBubbleText = styled(Typography)`
  white-space: pre-wrap;
`
export type Role = 'assistant' | 'user'

export type Chat = {
  message: string
  role: Role
  createdAt?: string
  cannotCopy?: boolean
  image?: {
    src: string
    text: string
    name: string
  }
}

type Props = {
  chat: Chat
  isHistory: boolean
}

type ContainerProps = BoxProps & {
  role: Role
}

type BubbleProps = BoxProps & {
  role: Role
  isHistory: boolean
}

export const ChatBubble = ({ chat, isHistory }: Props) => {
  const { copyToClipboard } = useTextCopy()

  const handleCopyToClipboard = () => {
    return void copyToClipboard(chat.message)
  }

  return (
    <Container role={chat.role}>
      <Bubble role={chat.role} isHistory={isHistory}>
        {chat.role === 'assistant' ? (
          <Markdown text={chat.message} />
        ) : (
          <UserBubbleText>{chat.message}</UserBubbleText>
        )}
      </Bubble>
      {chat.role === 'assistant' && !chat.cannotCopy && (
        <Button startIcon={<CopyIcon />}>
          <CopyButtonText onClick={handleCopyToClipboard}>コピー</CopyButtonText>
        </Button>
      )}
      {chat.createdAt && <DatetimeText>{chat.createdAt}</DatetimeText>}
    </Container>
  )
}
