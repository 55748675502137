import { Loading } from '@/components/uis/Loading/Loading'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

const Container = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`

const Text = styled(Box)`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.448;
`

export const ChatAnsweringText = () => {
  return (
    <Container>
      <Loading size={12} />
      <Text>回答生成中です。</Text>
    </Container>
  )
}
