import { useRef } from 'react'

import { useSnackbarContext } from '@/context/SnackbarContext'

type DownloadImageArgument = {
  fileName: string
}

export const useImageDownload = () => {
  const imageRef = useRef<HTMLImageElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const { showSnackbar } = useSnackbarContext()

  const showErrorMessage = () => {
    if (showSnackbar) {
      showSnackbar({
        text: '画像の保存に失敗しました',
        severity: 'error',
      })
    }
  }

  const showSuccessMessage = () => {
    if (showSnackbar) {
      showSnackbar({
        text: '画像を保存しました',
        severity: 'success',
      })
    }
  }

  const downloadImage = ({ fileName }: DownloadImageArgument) => {
    // 画像保存場所は別ドメインで画像を直接DLできないためcanvasに一度表示させてからDLしている
    const canvas = canvasRef.current
    const image = imageRef.current
    const context = canvas?.getContext('2d')

    if (canvas && image && context) {
      const loadedImage = new Image()
      loadedImage.crossOrigin = 'Anonymous'
      loadedImage.src = image.src

      // 画像の読み込みが終わったら処理を進める
      loadedImage.onload = () => {
        canvas.width = loadedImage.width
        canvas.height = loadedImage.height
        context.drawImage(loadedImage, 0, 0)

        try {
          canvas.toBlob((blob) => {
            if (blob) {
              const canvasUrl = canvas.toDataURL('image/jpeg', 1)
              const link = document.createElement('a')
              link.href = canvasUrl
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              link.remove()
              window.URL.revokeObjectURL(canvasUrl)

              showSuccessMessage()
            } else {
              showErrorMessage()
            }
          })
        } catch (error) {
          showErrorMessage()
        }
      }

      loadedImage.onerror = () => {
        showErrorMessage()
      }
    } else {
      showErrorMessage()
    }
  }

  return {
    imageRef,
    canvasRef,
    downloadImage,
  }
}
