import React from 'react'
import { InputHTMLAttributes, ReactElement } from 'react'
import styled from '@emotion/styled'
import {
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Checkbox,
} from '@mui/material'
import Link from 'next/link'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { accentColor, outlineColor } from '@/utils/themeConfigs/customTheme'

import { CodeHighLight } from './CodeHighLight'

const Strikethrough = styled.span`
  text-decoration: line-through;
`

const UlList = styled(List)`
  padding-left: 20px;
  & > li {
    display: list-item;
    list-style-type: disc;
    padding: 0;
  }
`

const OlList = styled(List)`
  padding-left: 20px;
  & > li {
    display: list-item;
    list-style-type: decimal;
    padding: 0;
  }
`

const NoneStyleListItem = styled(List)`
  list-style-type: none;
`

const DecorateLink = styled(Link)`
  color: ${accentColor.blue};
  text-decoration: underline;
  overflow-wrap: break-word;
`

const Blockquote = styled.blockquote`
  border-color: ${outlineColor.dark};
  border-left-style: solid;
  border-left-width: 2px;
  padding-left: 1px;
`

const H1 = styled(Typography)`
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.448;
`

const H2 = styled(Typography)`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.448;
`

const H3 = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.448;
`

const H4 = styled(Typography)`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.448;
`

const H5 = styled(Typography)`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.448;
`

const H6 = styled(Typography)`
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 1.448;
`

type Props = {
  text: string
}

export const Markdown = ({ text }: Props) => {
  // trueを返す場合、引数はReactElement<InputHTMLAttributes<HTMLInputElement>>型であるとする関数
  const isInputElement = (
    element: React.ReactNode,
  ): element is ReactElement<InputHTMLAttributes<HTMLInputElement>> => {
    return React.isValidElement(element) && element.type === 'input'
  }

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        h1: ({ children }) => <H1 variant='h1'>{children}</H1>,
        h2: ({ children }) => <H2 variant='h2'>{children}</H2>,
        h3: ({ children }) => <H3 variant='h3'>{children}</H3>,
        h4: ({ children }) => <H4 variant='h4'>{children}</H4>,
        h5: ({ children }) => <H5 variant='h5'>{children}</H5>,
        h6: ({ children }) => <H6 variant='h6'>{children}</H6>,
        a: ({ href, children }) => (
          <DecorateLink href={href ?? '#'}>{children}</DecorateLink>
        ),
        del: ({ children }) => <Strikethrough>{children}</Strikethrough>,
        input: ({ checked }) => <Checkbox checked={checked} disabled />,
        code: ({ children, className }) => (
          <CodeHighLight className={className}>{children}</CodeHighLight>
        ),
        blockquote: ({ children }) => <Blockquote>{children}</Blockquote>,

        // ********
        // テーブル関係
        // ********
        table: ({ children }) => (
          <TableContainer>
            <Table>{children}</Table>
          </TableContainer>
        ),
        tbody: ({ children }) => <TableBody>{children}</TableBody>,
        // TableHead使うとthの中にth作られるのでTableCellを使っている
        th: ({ children }) => <TableCell>{children}</TableCell>,
        tr: ({ children }) => <TableRow>{children}</TableRow>,
        td: ({ children }) => <TableCell>{children}</TableCell>,

        // ********
        // リスト関係
        // ********
        ul: ({ children }) => <UlList>{children}</UlList>,
        ol: ({ children }) => <OlList>{children}</OlList>,
        li: ({ children }) => {
          // - [ ] は li + inputに変換するのでチェックボックスの場合はliで黒丸を使わないようにする必要があるのでチェックボックスかチェックしている
          // children[0].props?.type === 'checkbox' でtrueだった場合、チェックボックス
          // ESLintのエラーを防ぐために型を確定させてから判定をしている
          if (
            children &&
            Array.isArray(children) &&
            children.every(React.isValidElement) &&
            isInputElement(children[0]) &&
            children[0]?.props?.type === 'checkbox'
          ) {
            return <NoneStyleListItem>{children}</NoneStyleListItem>
          }

          return (
            <ListItem>
              <ListItemText>{children}</ListItemText>
            </ListItem>
          )
        },
      }}
    >
      {text}
    </ReactMarkdown>
  )
}
